import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Box from '../../components/box'
import { AuthProvider, AuthContext } from '../../context/auth'
import config from '../../utils/config'
import checkUtmParams from '../../utils/checkUtmParams'
import { Button, Container, CopySubText, ErrorBanner, MiscTextLink } from '.'
import WorkspaceSetupSidebar from '../../components/workspaceSetupSidebar'
import WrongInput from '../../icons/wrongInput'
import CorrectInput from '../../icons/correctInput'
import { cloneDeep } from 'lodash'
import axios from 'axios'
import useOnEnterEventListener from '../../utils/hooks/useOnEnterEventListener'
import handleAuthNetworkError from '../../utils/handleAuthNetworkError'
import { emailRegex } from '../../utils/regex'
import useHotjarScript from '../../utils/hooks/useHotjarScript'

const InvitePeopleContainer = () => (
  <AuthProvider>
    <InvitePeople />
  </AuthProvider>
)

const FormBox = styled.div`
  margin-top: 5.5625rem;
  width: 100%;
  display: flex;
  justify-content: center;
`

const Header = styled.div`
  font-family: ${props => props.theme.typography.fontFamilyGT};
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
`

const Input = styled.input`
  width: 16.25rem;
  height: 3rem;
  max-width: 90vw;
  box-sizing: border-box;
  background: ${props => props.theme.core.background.tertiary};
  font-size: 1rem;
  line-height: 1.75rem;
  padding: ${props =>
    `${props.theme.spacing(1.75)} ${props.theme.spacing(2.5)}`};
  padding-right: 2.6875rem;
  border: ${props =>
    props.error ? '1px solid #EE0000' : '0.125rem solid transparent'};
  outline: none;
  transition: all 100ms ease-in-out;
  font-family: ${props => props.theme.typography.fontFamilyGTMono};
  :focus {
    background: ${props => props.theme.colors.white};
    border: 0.125rem solid
      ${props => (props.error ? '#EE0000' : props.theme.colors.black)};
  }
`

const InputLabel = styled.label`
  position: absolute;
  right: 1rem;
  font-family: ${props => props.theme.typography.fontFamilyGTMono};
  font-weight: 400;
  font-size: 0.75rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  top: 0.75rem;
  bottom: 0.75rem;
  color: #cbcbcb;
`

const InviteeInput = ({ email, onChange, userSession, placeholder }) => {
  const isValid = emailRegex.test(email) && email !== userSession.email
  return (
    <Box
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Input
        style={{
          width: '100%',
          marginTop: '0.5rem',
        }}
        error={email && !isValid}
        onChange={onChange}
        value={email}
        placeholder={placeholder}
      />
      {email ? (
        isValid ? (
          <InputLabel style={{ marginTop: '0.5rem' }}>
            <CorrectInput />
          </InputLabel>
        ) : (
          <InputLabel style={{ marginTop: '0.5rem' }}>
            <WrongInput />
          </InputLabel>
        )
      ) : null}
    </Box>
  )
}

const InvitePeople = () => {
  const [invitees, setInvitees] = useState([''])
  const { userSession, token, signupStepsMap, setLoading } = useContext(
    AuthContext
  )
  const [error, setError] = useState(null)
  useEffect(() => {
    checkUtmParams()
  }, [])

  const handleSubmit = async (skip = false) => {
    setLoading(true)
    try {
      const result = await axios.post(
        `${config.backendUri}/auth/session`,
        {
          userInvites: skip
            ? []
            : invitees
                .filter(email => emailRegex.test(email))
                .map(email => ({ email: email })),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      navigate(signupStepsMap.get(result.data.signupStep))
    } catch (error) {
      setLoading(false)
      setError(handleAuthNetworkError(error))
    }
  }

  // useOnEnterEventListener(
  //   invitees.every(email => !email || emailRegex.test(email)),
  //   () => {
  //     handleSubmit()
  //   }
  // )

  useEffect(() => {
    if (
      invitees.some(
        email =>
          email &&
          (!emailRegex.test(email) ||
            email.toLowerCase() === userSession.email.toLowerCase())
      )
    ) {
      setError(
        'Seems that you mistyped some email addresses. Please double-check and give it another try!'
      )
    } else {
      setError(null)
    }
  }, [invitees])

  useHotjarScript()

  if (!userSession) return null

  return (
    <Layout>
      <SEO title="Invite people | Sign up" />
      <WorkspaceSetupSidebar step={3} subStep={3} remainingTime="1 min" />
      <Container>
        <FormBox>
          <Box style={{ width: '25rem' }}>
            <Header style={{ color: 'black', fontSize: '1.25rem' }}>
              Who else should be on Pulse?
            </Header>
            <CopySubText
              style={{
                marginTop: '1rem',
                fontSize: '0.875rem',
                lineHeight: '1.375rem',
              }}
            >
              To give Pulse a spin, invite anyone who should read or write
              updates in this workspace.
              {/* <br />
              <br />
              Invite people within your company domain{' '} */}
            </CopySubText>
            <Box style={{ marginTop: '1rem' }}>
              {invitees.map((email, index) => {
                return (
                  <InviteeInput
                    key={index}
                    email={email}
                    userSession={userSession}
                    placeholder={`example@${userSession.email.split('@')[1]}`}
                    onChange={event => {
                      const newValue = event.target.value
                      const inviteesCopy = cloneDeep(invitees)
                      inviteesCopy[index] =
                        newValue && typeof newValue === 'string'
                          ? newValue.toLowerCase()
                          : newValue

                      if (newValue && index === invitees.length - 1) {
                        setInvitees([...inviteesCopy, ''])
                      } else if (!newValue) {
                        inviteesCopy.splice(index, 1)
                        setInvitees(inviteesCopy)
                      } else {
                        setInvitees(inviteesCopy)
                      }
                    }}
                  />
                )
              })}
            </Box>
            <Button
              style={{ width: '100%', height: '3rem', marginTop: '1rem' }}
              onClick={() => handleSubmit()}
              disabled={
                invitees.length === 1 ||
                !invitees.slice(0, invitees.length - 1).every(
                  email =>
                    emailRegex.test(email) &&
                    // email.split('@')[1] === userSession.email.split('@')[1]
                    email.toLowerCase() !== userSession.email.toLowerCase()
                )
              }
            >
              Send Invites
            </Button>
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <MiscTextLink
                href="#"
                style={{ color: '#757575' }}
                onClick={event => {
                  event.preventDefault()
                  handleSubmit(true)
                }}
              >
                Skip
              </MiscTextLink>
            </Box>
            {error && (
              <ErrorBanner style={{ marginTop: '1rem' }}>{error}</ErrorBanner>
            )}
          </Box>
        </FormBox>
      </Container>
    </Layout>
  )
}

export default InvitePeopleContainer
